import {
    iconInfoWhite,
    iconInfoBlack,
    iconMarketingWhite,
    iconMarketingBlack,
    iconGameWhite,
    iconGameBlack,
    iconRechargeWhite,
    iconRechargeBlack,
    iconWithdrawWhite,
    iconWithdrawBlack,
    iconTranferWhite,
    iconTranferBlack,
    iconHistoryWhite,
    iconHistoryBlack,
    iconYKienWhite,
    iconYKienBlack,
    iconQuyTienWhite,
    iconQuyTienBlack,
    iconKetQuaWhite,
    iconKetQuaBlack,
    iconCachChoiWhite,
    iconCachChoiBlack,
    tai,
    xiu,
    le,
    chan,
    xucsac1,
    xucsac3d1,
    xucsac2,
    xucsac3d2,
    xucsac3,
    xucsac3d3,
    xucsac4,
    xucsac3d4,
    xucsac5,
    xucsac3d5,
    xucsac6,
    xucsac3d6,
    chip5k,
} from './img/importImg';
import MyMainContent from './components/MyMainContent/MyMainContent';
import RechargeBank from './components/Recharge/RechargeBank';
import { chip10k } from './img/importImg';
import { chip25k } from './img/importImg';
import { chip50k } from './img/importImg';
import { chip100k } from './img/importImg';
import { chip200k } from './img/importImg';
import { chip500k } from './img/importImg';
import { chip1000k } from './img/importImg';

export const baseUrl =
    window.location.hostname === 'localhost' ? 'http://localhost:6688' : `https://api.${window.location.hostname}`;

// export const baseUrl = 'https://test.vnbet01.com';

export const webSocketUrl =
    window.location.hostname === 'localhost'
        ? 'ws://localhost:5000/connect'
        : `wss://game.${window.location.hostname}/connect`;

export const listThongTinCaNhan = [
    {
        title: 'Thông tin của tôi',
        component: '',
    },
    {
        title: 'Thay đổi mật khẩu',
        component: '',
    },
    {
        title: 'Thay đổi mật khẩu rút tiền',
        component: '',
    },
];

export const listMarketingToanCau = [
    {
        title: 'Thuyết minh',
        component: '',
    },
    {
        title: 'Báo biểu',
        component: '',
    },
    {
        title: 'Tiền hoa hồng',
        component: '',
    },
    {
        title: 'Mã giới thiệu',
        component: '',
    },
    {
        title: 'Thành viên',
        component: '',
    },
    {
        title: 'Đặt cược',
        component: '',
    },
];

export const listMieuTaTroChoi = [
    {
        title: 'Miền Bắc',
    },
    {
        title: 'ON LIVE',
    },
    {
        title: 'Trò Chơi',
    },
    {
        title: 'Siêu Tốc',
    },
    {
        title: 'Mega 6/45',
    },
    {
        title: 'Xổ Số VIP',
    },
    {
        title: 'Miền Nam',
    },
    {
        title: 'Miền Trung',
    },
    {
        title: 'Thái Lan',
    },
];

export const listCachChoi = [
    {
        title: 'Miền Bắc',
    },
    {
        title: 'ON LIVE',
    },
    {
        title: 'Trò Chơi',
    },
    {
        title: 'Siêu Tốc',
    },
    {
        title: 'Mega 6/45',
    },
    {
        title: 'Xổ Số VIP',
    },
    {
        title: 'Miền Nam',
    },
    {
        title: 'Miền Trung',
    },
    {
        title: 'Thái Lan',
    },
];

export const listNapTien = [
    {
        title: 'Chuyển khoản nhanh 24/7',
    },
    {
        title: 'Lịch sử nạp tiền',
        component: '',
    },
];

export const listRutTien = [
    {
        title: 'Rút Tiền',
        component: '',
    },
    {
        title: 'Hồ Sơ Rút Tiền',
        component: '',
    },
    {
        title: 'Liên kết ngân hàng',
        component: '',
    },
    // {
    //   title: "Thay Đổi Mật Khẩu Rút Tiền",
    //   component: "",
    // },
    // {
    //   title: "Thông Tin Thêm",
    //   component: "",
    // },
];

export const listChuyenQuy = [
    {
        // title: "Hạn mức chuyển đổi",
        title: 'Chi tiết quỹ tiền',
        component: '',
    },
    {
        // title: "Lịch sử chuyển đổi",
        title: '',
        component: '',
    },
];

export const listLichSuCuoc = [
    {
        title: 'Toàn bộ',
        component: '',
    },
    // {
    //   title: "Vé số",
    //   component: "",
    // },
    // {
    //     title: 'IB',
    //     component: '',
    // },
    // {
    //     title: 'S2',
    //     component: '',
    // },
    // {
    //     title: 'PZ',
    //     component: '',
    // },
    // {
    //     title: 'CQ',
    //     component: '',
    // },
    // {
    //     title: 'HG',
    //     component: '',
    // },
    // {
    //     title: 'SG',
    //     component: '',
    // },
    // {
    //     title: 'DG',
    //     component: '',
    // },
    // {
    //     title: 'DS',
    //     component: '',
    // },
    // {
    //     title: 'GS',
    //     component: '',
    // },
    // {
    //     title: 'RE',
    //     component: '',
    // },
];

export const listQuyTien = [
    {
        title: 'Nạp tiền',
        component: '',
    },
    {
        title: 'Rút tiền',
        component: '',
    },
    {
        title: 'Ví tiền',
        component: '',
    },
    {
        title: 'Phần thưởng',
        component: '',
    },
    {
        title: 'Lịch sử hoàn trả',
        component: '',
    },
];

export const listYKien = [
    {
        title: 'Ý kiến phản hồi',
        component: '',
    },
    {
        title: 'Lịch sử phản hồi',
        component: '',
    },
];

export const listKetQua = [
    {
        title: 'Miền Bắc',
        component: '',
    },
    {
        title: 'ON LIVE',
        component: '',
    },
    {
        title: 'Trò Chơi',
        component: '',
    },
    {
        title: 'Siêu Tốc',
        component: '',
    },
    {
        title: 'Mega 6/45',
        component: '',
    },
    {
        title: 'Xổ Số VIP',
        component: '',
    },
    {
        title: 'Miền Nam',
        component: '',
    },
    {
        title: 'Miền Trung',
        component: '',
    },
    {
        title: 'Thái Lan',
        component: '',
    },
];

export const listMy = [
    {
        title: 'Thông tin cá nhân',
        iconActive: iconInfoWhite,
        icon: iconInfoBlack,
        component: MyMainContent,
        data: listThongTinCaNhan,
        params: 'info',
    },
    // {
    //   title: "Marketing toàn cầu",
    //   iconActive: iconMarketingWhite,
    //   icon: iconMarketingBlack,
    //   component: "",
    //   data: listMarketingToanCau,
    //   params: "marketing",
    // },
    // {
    //   title: "Miêu tả trò chơi",
    //   iconActive: iconGameWhite,
    //   icon: iconGameBlack,
    //   component: "",
    //   data: listMieuTaTroChoi,
    //   params: "game",
    // },
    // {
    //   title: "Cách chơi",
    //   iconActive: iconCachChoiWhite,
    //   icon: iconCachChoiBlack,
    //   component: "",
    //   data: listCachChoi,
    //   params: "play",
    // },
    {
        title: 'Nạp tiền',
        iconActive: iconRechargeWhite,
        icon: iconRechargeBlack,
        component: '',
        data: listNapTien,
        params: 'recharge',
    },
    {
        title: 'Rút tiền',
        iconActive: iconWithdrawWhite,
        icon: iconWithdrawBlack,
        component: '',
        data: listRutTien,
        params: 'withdraw',
    },
    {
        title: 'Chuyển quỹ',
        iconActive: iconTranferWhite,
        icon: iconTranferBlack,
        component: '',
        data: listChuyenQuy,
        params: 'transfer',
    },
    {
        title: 'Lịch sử cược',
        iconActive: iconHistoryWhite,
        icon: iconHistoryBlack,
        component: '',
        data: listLichSuCuoc,
        params: 'history',
    },
    {
        title: 'Quỹ tiền',
        iconActive: iconQuyTienWhite,
        icon: iconQuyTienBlack,
        component: '',
        data: listQuyTien,
        params: 'money',
    },
    {
        title: 'Ý kiến phản hồi',
        iconActive: iconYKienWhite,
        icon: iconYKienBlack,
        component: '',
        data: listYKien,
        params: 'feedback',
    },
    // {
    //   title: "Kết quả mở thưởng",
    //   iconActive: iconKetQuaWhite,
    //   icon: iconKetQuaBlack,
    //   component: "",
    //   data: listKetQua,
    //   params: "result",
    // },
];

export const tonghopOld = [
    {
        content: tai,
        isContent: true,
        tienDatCuoc: 0,
        tiLe: 1.985,
        value: 'tai',
        isActive: false,
        isHidden: false,
    },
    {
        content: 'tai',
        isContent: true,
        tienDatCuoc: 0,
        tiLe: 1.985,
        value: 'tainone',
        isActive: false,
        isHidden: true,
    },
    {
        content: 'chan',
        isContent: true,
        tienDatCuoc: 0,
        tiLe: 1.985,
        value: 'channone',
        isActive: false,
        isHidden: true,
    },
    {
        content: le,
        isContent: true,
        tienDatCuoc: 0,
        tiLe: 1.985,
        value: 'le',
        isActive: false,
        isHidden: false,
    },
    {
        content: xiu,
        isContent: true,
        tienDatCuoc: 0,
        tiLe: 1.985,
        value: 'xiu',
        isActive: false,
        isHidden: false,
    },
    {
        content: 'xiu',
        isContent: true,
        tienDatCuoc: 0,
        tiLe: 1.985,
        value: 'xiunone',
        isActive: false,
        isHidden: true,
    },
    {
        content: 'le',
        isContent: true,
        tienDatCuoc: 0,
        tiLe: 1.985,
        value: 'lenone',
        isActive: false,
        isHidden: true,
    },
    {
        content: chan,
        isContent: true,
        tienDatCuoc: 0,
        tiLe: 1.985,
        value: 'chan',
        isActive: false,
        isHidden: false,
    },
    {
        content: '4',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 60,
        value: 'bon',
        isActive: false,
        isHidden: false,
    },
    {
        content: '4none',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 60,
        value: 'bonnone',
        isActive: false,
        isHidden: true,
    },
    {
        content: '5none',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 30,
        value: 'namnone',
        isActive: false,
        isHidden: true,
    },
    {
        content: '5',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 30,
        value: 'nam',
        isActive: false,
        isHidden: false,
    },
    {
        content: '6',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 17,
        value: 'sau',
        isActive: false,
        isHidden: false,
    },
    {
        content: '7',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 12,
        value: 'bay',
        isActive: false,
        isHidden: false,
    },
    {
        content: '8',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 8,
        value: 'tam',
        isActive: false,
        isHidden: false,
    },
    {
        content: '9',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 6,
        value: 'chin',
        isActive: false,
        isHidden: false,
    },
    {
        content: '10',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 6,
        value: 'muoi',
        isActive: false,
        isHidden: false,
    },
    {
        content: '11',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 6,
        value: 'muoi1',
        isActive: false,
        isHidden: false,
    },
    {
        content: '12',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 6,
        value: 'muoi2',
        isActive: false,
        isHidden: false,
    },
    {
        content: '13',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 8,
        value: 'muoi3',
        isActive: false,
        isHidden: false,
    },
    {
        content: '14',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 12,
        value: 'muoi4',
        isActive: false,
        isHidden: false,
    },
    {
        content: '15',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 17,
        value: 'muoi5',
        isActive: false,
        isHidden: false,
    },
    {
        content: '16',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 30,
        value: 'muoi6',
        isActive: false,
        isHidden: false,
    },
    {
        content: '17',
        isContent: false,
        tienDatCuoc: 0,
        tiLe: 60,
        value: 'muoi7',
        isActive: false,
        isHidden: false,
    },
];

export const ketQuaDice = [
    {
        value: '1',
        img: xucsac1,
        img3D: xucsac3d1,
    },
    {
        value: '2',
        img: xucsac2,
        img3D: xucsac3d2,
    },
    {
        value: '3',
        img: xucsac3,
        img3D: xucsac3d3,
    },
    {
        value: '4',
        img: xucsac4,
        img3D: xucsac3d4,
    },
    {
        value: '5',
        img: xucsac5,
        img3D: xucsac3d5,
    },
    {
        value: '6',
        img: xucsac6,
        img3D: xucsac3d6,
    },
];

export const infoCuoc = {
    xiu: 'Xỉu',
    chan: 'Chắn',
    le: 'Lẻ',
    tai: 'Tài',
    bon: '4',
    nam: '5',
    sau: '6',
    bay: '7',
    tam: '8',
    chin: '9',
    muoi: '10',
    muoi1: '11',
    muoi2: '12',
    muoi3: '13',
    muoi4: '14',
    muoi5: '15',
    muoi6: '16',
    muoi7: '17',
};

export const arrChip = [
    {
        img: chip5k,
        value: 5000,
    },
    {
        img: chip10k,
        value: 10000,
    },
    {
        img: chip25k,
        value: 25000,
    },
    {
        img: chip50k,
        value: 50000,
    },
    {
        img: chip100k,
        value: 100000,
    },
    {
        img: chip200k,
        value: 200000,
    },
    {
        img: chip500k,
        value: 500000,
    },
    {
        img: chip1000k,
        value: 1000000,
    },
];

export const chuSo = [
    {
        title: '0',
        value: 0,
    },
    {
        title: '1',
        value: 1,
    },
    {
        title: '2',
        value: 2,
    },
    {
        title: '3',
        value: 3,
    },
    {
        title: '4',
        value: 4,
    },
    {
        title: '5',
        value: 5,
    },
    {
        title: '6',
        value: 6,
    },
    {
        title: '7',
        value: 7,
    },
    {
        title: '8',
        value: 8,
    },
    {
        title: '9',
        value: 9,
    },
];

export const listTheLoai = [
    {
        title: 'Bao Lô',
        isHot: false,
        value: 'baolo',
        listType: [
            {
                title: 'Lô 2 Số',
                isHot: true,
                value: 'lo2',
                placeholder: '10,20,30 hoặc 10 20 30',
                minCuoc: 27000,
                tiLe: 1,
            },
        ],
    },
    {
        title: 'Lô Xiên',
        isHot: false,
        value: 'loxien',
        listType: [
            {
                title: 'Xiên 2',
                isHot: false,
                value: 'xien2',
                placeholder: '15,63 hoặc 15 63',
                minCuoc: 1000,
                tiLe: 1,
            },
            {
                title: 'Xiên 3',
                isHot: false,
                value: 'xien3',
                placeholder: '15,63,82 hoặc 15 63 82',
                minCuoc: 1000,
                tiLe: 1,
            },
            {
                title: 'Xiên 4',
                isHot: true,
                value: 'xien4',
                placeholder: '15,63,82,91 hoặc 15 63 82 91',
                minCuoc: 1000,
                tiLe: 1,
            },
        ],
    },
    {
        title: 'Đánh Đề',
        isHot: true,
        value: 'danhde',
        listType: [
            {
                title: 'Đề (Đặc Biệt)',
                isHot: true,
                value: 'de',
                placeholder: '11,24,37 hoặc 11 24 37',
                minCuoc: 1000,
                tiLe: 1,
            },
            {
                title: 'Đề (Đầu Đặc Biệt)',
                isHot: false,
                value: 'daude',
                placeholder: '06,25,31 hoặc 06 25 31',
                minCuoc: 1000,
                tiLe: 1,
            },
        ],
    },
    {
        title: 'Đầu Đuôi',
        isHot: true,
        value: 'dauduoi',
        listType: [
            {
                title: 'Đầu',
                isHot: false,
                value: 'dau',
                placeholder: '1,5,9 hoặc 1 5 9',
                minCuoc: 1000,
                tiLe: 1,
            },
            {
                title: 'Đuôi',
                isHot: true,
                value: 'duoi',
                placeholder: '2,4,8 hoặc 2 4 8',
                minCuoc: 1000,
                tiLe: 1,
            },
        ],
    },
    {
        title: '3 Càng',
        isHot: true,
        value: '3cang',
        listType: [
            {
                title: '3 Càng (Đặc Biệt)',
                isHot: true,
                value: '3cang',
                placeholder: '106,257,317 hoặc 106 257 317',
                minCuoc: 1000,
                tiLe: 1,
            },
        ],
    },
];
